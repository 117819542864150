
import { defineComponent, ref, onMounted, computed } from 'vue'
import { useCarWash } from '@/composables/useCarWash'
import InputAmount from '@/components/InputAmount.vue'
import { useRoute, useRouter } from 'vue-router'
import { useGeolocation } from '@/composables/useGeolocation'
import { ICarWashStockItem, ICarWash, ICarWashStockItemToUpdate } from '@/interfaces/ICarWash'
import { IBarrelRefillPayload } from '@/interfaces/IBarrel'

export default defineComponent({
    components: {
        InputAmount,
    },
    setup() {
        const {
            isLoading,
            activeCarWash,
            activeCarWashStock,
            fetchCarWash,
            fetchCarWashStock,
            refillBarrel,
        } = useCarWash()
        const router = useRouter()
        const carWash = ref<ICarWash>()
        const productToAdd = ref<ICarWashStockItemToUpdate | null>()
        const isProductChosen = ref(false)
        const route = useRoute()
        const carWashId = ref(+route.params.id)
        const { fetchCoordinates } = useGeolocation()

        const showProductsToRefill = (product: ICarWashStockItem) => {
            return (
                product.productGroupId === activeBarrel.value?.productGroupId &&
                !product.isContainer
            )
        }

        const resetProductToAdd = () => {
            productToAdd.value = null
            isProductChosen.value = false
        }

        const getProductAmount = (productId: number) => {
            if (productToAdd.value && productToAdd.value.id === productId) {
                return productToAdd.value.quantityWarehouse
            }
        }
        const addProductAmount = (product: ICarWashStockItem, quantity: number) => {
            if (quantity === 0) {
                isProductChosen.value = false
                return false
            }
            isProductChosen.value = true
            const objectToPush: ICarWashStockItemToUpdate = {
                id: product.id,
                quantityWarehouse: quantity,
            }
            productToAdd.value = objectToPush
        }

        const fetchDetails = async () => {
            carWash.value = await fetchCarWash(carWashId.value)
            await fetchCoordinates()
            fetchCarWashStock()
        }

        const activeBarrel = computed(() => {
            return activeCarWashStock.value.barrels.find(el => el.id === +route.params.barrelId)
        })

        const submitRefill = async () => {
            if (!activeCarWash.value || !activeBarrel.value || !productToAdd.value) {
                return
            }
            const refillPayload: IBarrelRefillPayload = {
                destinationWarehouseId: activeCarWash.value.id,
                barrelId: activeBarrel.value.id,
                products: [productToAdd.value],
            }
            const response = await refillBarrel(refillPayload)
            if (response) {
                router.push({ name: 'CarWashDetails', params: { id: activeCarWash.value.id } })
            }
        }

        onMounted(async () => {
            if (route.name === 'CarWashTankRefillCourier') carWashId.value = +route.params.carWashId
            await fetchDetails()
        })

        return {
            activeCarWash,
            activeCarWashStock,
            productToAdd,
            getProductAmount,
            addProductAmount,
            isProductChosen,
            router,
            activeBarrel,
            resetProductToAdd,
            isLoading,
            submitRefill,
            showProductsToRefill,
        }
    },
})
